import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MemberOutcomes from './MemberOutcomes'

const SunlifeMSKMemberOutcomes = () => {
  const icons = useStaticQuery(iconsQuery)

  const items = [
    {
      color: 'grapefruit',
      imageURL: icons.memberMeghan.publicURL,
      imageName: icons.memberMeghan.name,
      memberName: 'Meghan',
      memberContent: ['35 years old', 'Upper back pain'],
      before: ['Unable to exercise', 'Stressed', 'Not sleeping well'],
      after: [
        'Starting to incorporate mild exercise again',
        'Stress back in normal ranges',
        'Sleeping 6 hours each night uninterrupted'
      ]
    },
    {
      color: 'slate',
      imageURL: icons.memberGeorge.publicURL,
      imageName: icons.memberGeorge.name,
      memberName: 'George',
      memberContent: ['40 years old', 'Lower back pain'],
      before: [
        'Weekly pain flare-ups',
        'Moderate to severe levels of pain',
        'Stress about constant pain led to anxiety'
      ],
      after: [
        'Pain flare-ups nearly gone',
        'Pain levels reduced to mild',
        'Anxiety in normal ranges'
      ]
    },
    {
      color: 'larimar',
      imageURL: icons.memberOscar.publicURL,
      imageName: icons.memberOscar.name,
      memberName: 'Oscar',
      memberContent: ['55 years old', 'Lower back pain'],
      before: [
        'Staying seated each day worsens his pain',
        'Diet has been exacerbating pain'
      ],
      after: [
        'Posture and exercises shifted pain to mild ranges',
        'Shift in diet & activity even led to mild weight loss'
      ]
    }
  ]

  return <MemberOutcomes items={items} />
}

export default SunlifeMSKMemberOutcomes

const iconsQuery = graphql`
  query iconsSunlifeMSKMemberOutcomesQuery {
    memberMeghan: file(
      relativePath: { eq: "employer/memberOutcomes/memberMeghan.png" }
    ) {
      name
      publicURL
    }
    memberGeorge: file(
      relativePath: { eq: "employer/memberOutcomes/memberGeorge.png" }
    ) {
      name
      publicURL
    }
    memberOscar: file(
      relativePath: { eq: "employer/memberOutcomes/memberOscar.png" }
    ) {
      name
      publicURL
    }
  }
`
