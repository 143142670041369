import React, { useEffect, useMemo } from 'react'
import { NoIndex } from '../../components/NoIndex'

import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import { EmployerPageProvider } from '../../contexts/EmployerPage/employerPageContext'
import SunlifeHero from '../../components/Employer/heros/sunlife/SunlifeHero'
import PersonalizedCare from '../../components/Employer/PersonalizedCare'
import HowItsDifferent from '../../components/HowItsDifferent'
import SunlifeMSKMemberOutcomes from '../../components/Employer/SunlifeMSKMemberOutcomes'
import SunlifeBackPainFaq from '../../components/Employer/SunlifeBackPainFaq'
import ReadyToEnroll from '../../components/Employer/ReadyToEnroll'
import EmployerFooter from '../../components/Employer/EmployerFooter'
import VideoSection from '../../components/Conditions/Common/VideoSection'
import SunlifeMSKBackedByMedical from '../../components/Employer/SunlifeMSKBackedByMedical'
import SunlifeFooterExtend from '../../components/Employer/SunlifeFooterExtend'

const SunlifeBackEnrollment = ({ pageContext: { slug, employer } }) => {
  console.log(`EmployerPage employer`, employer)
  const { setEmployerSlug } = useEmployerContext()

  useEffect(() => {
    if (slug) {
      setEmployerSlug(slug)
    }
  }, [slug, setEmployerSlug])

  const selectConditionLink = useMemo(
    () => `/signup?e=${slug}&condition=back-pain`,
    [slug]
  )

  return (
    <EmployerPageProvider>
      <NoIndex />

      {/* Hero */}
      <SunlifeHero condition={'back-pain'} ctaLink={selectConditionLink} />

      {/* Personalized Care */}
      <PersonalizedCare condition={'back-pain'} />

      {/* How it's different */}
      <HowItsDifferent condition={'back-pain'} ctaLink={selectConditionLink} />

      {/* Backed by medical */}
      <SunlifeMSKBackedByMedical />

      {/* Member Outcomes */}
      <SunlifeMSKMemberOutcomes />

      {/* Video Long Covid */}
      <VideoSection
        background={'alabaster'}
        videoId={'ll273ivnf2'}
        ctaLink={selectConditionLink}
        button
      />

      {/* FAQ */}
      <SunlifeBackPainFaq />

      {/* Ready to enroll */}
      <ReadyToEnroll ctaLink={selectConditionLink} />

      {/* Footer */}
      <EmployerFooter
        background={'bg-gp-white'}
        footerExtend={<SunlifeFooterExtend />}
      />
    </EmployerPageProvider>
  )
}

export default SunlifeBackEnrollment
