import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackedByMedical from './BackedByMedical'

const SunlifeMSKBackedByMedical = () => {
  const icons = useStaticQuery(iconsQuery)

  const coachesContent = [
    {
      color: 'grapefruit',
      title: 'PAIN, ORTHO, INTERNAL MEDICINE PHYSICIANS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.painPhysiciansBackPain.publicURL,
      imageAltDesktop: icons.painPhysiciansBackPain.name,
      imageSrcMobile: icons.painPhysiciansBackPainMobile.publicURL,
      imageAltMobile: icons.painPhysiciansBackPainMobile.name
    },
    {
      color: 'larimar',
      title: 'SLEEP, DIGESTIVE, & MIND-BODY PHYSICIANS & PHDS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.sleepPhysicians.publicURL,
      imageAltDesktop: icons.sleepPhysicians.name,
      imageSrcMobile: icons.sleepPhysiciansMobile.publicURL,
      imageAltMobile: icons.sleepPhysiciansMobile.name
    },
    {
      color: 'slate',
      title: 'RESEARCH & CLINICAL DEVELOPMENT PHYSICIANS',
      imageClass: 'w-70 md:w-7/12',
      imageSrcDesktop: icons.researchPhysicians.publicURL,
      imageAltDesktop: icons.researchPhysicians.name,
      imageSrcMobile: icons.researchPhysiciansMobile.publicURL,
      imageAltMobile: icons.researchPhysiciansMobile.name
    },
    {
      color: 'honey',
      title: 'NUTRITION, BEHAVIORAL HEALTH, & PHYSICAL THERAPY EXPERTS',
      imageClass: 'w-70 md:w-10/12',
      imageSrcDesktop: icons.nutritionPhysicians.publicURL,
      imageAltDesktop: icons.nutritionPhysicians.name,
      imageSrcMobile: icons.nutritionPhysiciansMobile.publicURL,
      imageAltMobile: icons.nutritionPhysiciansMobile.name
    }
  ]

  return <BackedByMedical coachesContent={coachesContent} />
}

export default SunlifeMSKBackedByMedical

const iconsQuery = graphql`
  query iconsMSKBackedByMedicalQuery {
    painPhysiciansBackPain: file(
      relativePath: {
        eq: "employer/backedbymedical/painPhysiciansBackPain.png"
      }
    ) {
      name
      publicURL
    }
    painPhysiciansBackPainMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/painPhysiciansBackPain-mobile.png"
      }
    ) {
      name
      publicURL
    }
    sleepPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/sleepPhysicians.png" }
    ) {
      name
      publicURL
    }
    sleepPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/sleepPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
    researchPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/researchPhysicians.png" }
    ) {
      name
      publicURL
    }
    researchPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/researchPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
    nutritionPhysicians: file(
      relativePath: { eq: "employer/backedbymedical/nutritionPhysicians.png" }
    ) {
      name
      publicURL
    }
    nutritionPhysiciansMobile: file(
      relativePath: {
        eq: "employer/backedbymedical/nutritionPhysicians-mobile.png"
      }
    ) {
      name
      publicURL
    }
  }
`
